import React, { useRef, useState } from "react"
import Layout from "../components/layout"
import ContactSuccess from "../components/contact-success"
import SEO from "../components/seo"
import API from "../constants/api"
import ReCAPTCHA from "react-google-recaptcha"
import "../password.css"

const ResetPassword = () => {
  const recaptchaRef = useRef(null)
  const finalValues = useRef(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [passwordIsReset, setPasswordIsReset] = useState(false)
  const [errType, setErrType] = useState("")
  const [email, setEmail] = useState("")
  const handleSubmit = e => {
    e.preventDefault()

    const vali = validateForm()
    if (vali) {
      setErrType("")
      finalValues.current = { email: email }
      recaptchaRef.current.execute()
    }
  }
  const validateForm = () => {
    if (email === "" || !email.includes("@") || !email.includes(".")) {
      setErrType("email")
      return false
    }
    return true
  }
  const handleFormSubmit = tok => {
    if (isProcessing) return
    setIsProcessing(true)
    let data = new FormData()
    data.append("email", finalValues.current.email)
    data.append("token", tok)
    fetch(`${API.SUPPORT}send_pass_reset.php`, {
      method: "POST",
      body: data,
      headers: {
        "X-XSRF-Header": API.XSRF,
      },
    })
      .then(res => res.json())
      .then(res => {
        setIsProcessing(false)
        setPasswordIsReset(true)
      })
  }
  return (
    <Layout>
      <SEO title="Reset Your Password" />
      <div className="section_form">
        <div className="section_background">
          <img src={"/af-bg.jpg"} alt="" />
        </div>
        <div className="block_form passCenter">
          <form action="#">
            <div className="block_form_wrapper">
              <>
                <div className="heading">
                  {passwordIsReset ? (
                    <h2 className="title">
                      Email <strong>Sent</strong>
                    </h2>
                  ) : (
                    <h2 className="title">
                      Reset <strong>Password</strong>
                    </h2>
                  )}
                </div>
                {passwordIsReset ? null : (
                  <div className="field">
                    <div className="row">
                      <div className="wrap">
                        <label htmlFor="email" className="label">
                          Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          className={
                            errType === "email" ? "input error" : "input"
                          }
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                        />
                        {errType === "email" ? (
                          <div className="errorMsg">Error</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        onChange={e => handleFormSubmit(e)}
                        theme="dark"
                        badge="inline"
                        size="invisible"
                        sitekey="6LdFnKgUAAAAAMMrHjMOzd42QrbFzhO-aO_Rme2k"
                      />
                    </div>

                    <div className="row">
                      <button
                        className={
                          isProcessing
                            ? "button_more processing"
                            : "button_more"
                        }
                        onClick={handleSubmit}
                      >
                        Submit
                        <div className="nk-spinner"></div>
                      </button>
                    </div>
                  </div>
                )}
              </>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default ResetPassword
